import React, {Suspense, lazy} from 'react'
import NavBar from '../NavBar/NavBar'
import CountryMainSection from '../CountryMainSection/CountryMainSection'


const Footer = lazy(()=>{return import('../../parts/Footer')})




const CountryPage  = () => {
    
    return (
        <div>
          <NavBar />
          <div style={{minHeight: 700+'px'}}>
          <Suspense fallback={<><div></div></>}>
            <CountryMainSection />
          </Suspense>
          </div>
          <Suspense fallback={<><div></div></>}>
            <Footer />
          </Suspense>
        </div>
      );
  
};

export default CountryPage
