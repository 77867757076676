import React, {Suspense, lazy} from 'react'
import * as app_styles from './App.module.scss'
import NavBar from './components/NavBar/NavBar'
import CountryPage from './components/CountryPage/CountryPage'
import MainSection from './components/MainSection/MainSection'
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route,
  useParams
} from "react-router-dom";

import countries_avaliable from "./utils/countries_avaliable.json"

const HowItWorks = lazy(()=>{return import('./components/HowItWorks/HowItWorks')})
const Comments = lazy(()=>{return import('./components/Comments/Comments')})
// const Tarifs = lazy(()=>{return import('./components/Tarifs/Tarifs')})
const OffertMore = lazy(()=>{return import('./components/OffertMore/OffertMore')})
const Footer = lazy(()=>{return import('./parts/Footer')})

// const Spinner = () => {
//   return (<div>Loading</div>)
// }



const Application = (props) => {
   console.log(props.urlParams);
  return (
    <div className={app_styles.main_container}>
    <NavBar />
    <MainSection />
    <Suspense fallback={<><div></div></>}>
      <OffertMore />
    </Suspense>
    <Suspense fallback={<><div></div></>}>
      <HowItWorks />
    </Suspense>
    <Suspense fallback={<><div></div></>}>
      <Comments />
    </Suspense>
    {/* <Suspense fallback={<><div></div></>}>
      <Tarifs />
    </Suspense> */}
    <Suspense fallback={<><div></div></>}>
      <Footer />
    </Suspense>


    {/* <div style={{marginBottom: "400px", height: "300px"}}></div> */}
  </div>
  )
}
function App () {

  let default_country = "GP";
  let default_language = "fr";

  let { country } = useParams();

  const isInList = (_country) => {
   return  countries_avaliable.codes.includes(String(_country).toUpperCase())
  } 
  const getCountry = (_country) => {

      let c = _country;
      if (!isInList(_country))  {
        c = default_country
      }

      return c.toLowerCase();
  }

  return(

  <Router>
        <Switch>

          <Route path="/home">
            <Application />
          </Route>
          <Route exact path="/country">
            <CountryPage />
          </Route>
       
        </Switch>
    </Router>
  )







  // console.log('ss',props)
  // const { t, i18n } = useTranslation();
  return (
    <>
      <div className={app_styles.main_container}>
        <NavBar />
        <MainSection />
        <Suspense fallback={<><div></div></>}>
          <OffertMore />
        </Suspense>
        <Suspense fallback={<><div></div></>}>
          <HowItWorks />
        </Suspense>
        <Suspense fallback={<><div></div></>}>
          <Comments />
        </Suspense>
        {/* <Suspense fallback={<><div></div></>}>
          <Tarifs />
        </Suspense> */}
        <Suspense fallback={<><div></div></>}>
          <Footer />
        </Suspense>



        {/* <div style={{marginBottom: "400px", height: "300px"}}></div> */}
      </div>

    </>
  )
}

export default App
