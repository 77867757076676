import React from 'react'
import * as styles from './MainSection.module.scss'
import Form from './Form/Form'
import { useTranslation } from 'react-i18next';

import { useParams } from "react-router-dom";

function MainSection () {
    let { country } = useParams();
    console.log(country, typeof country)
    const { t } = useTranslation();
  
    return (
    <div className={styles.main_wrapper_p}>
      {/* {country} */}
      <div className={`container`}>
        <div className={'row ' +styles.res_fix}>
          <div className={'col-lg-6 pt-4' + styles.formfix}>
            <Form />
          </div>
          <div className='col-lg-6 mt-5'>
            <div className={styles.text_content}>
              <h1>{t('homeTextLine1')}</h1>
              <p>
              {t('homeTextLine2')}<br />
              {t('faster with us!')}

                {/* Créez votre boutique en ligne plus <br /> rapidement avec nous ! */}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainSection
