import React from 'react'
import * as styles from './CountryMainSection.module.scss'
import { useTranslation } from 'react-i18next';

import { useParams } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component'
import munequito from '../../assets/img/munequito.svg'

function MainSection () {
    let { country } = useParams();
    console.log(country, typeof country)
    const { t } = useTranslation();
  
    return (
    <div className={styles.main_wrapper_p}>
      {/* {country} */}
      <div className={`formfix` + styles.formfix}>
        <div className={'row ' +styles.res_fix}>
          <div className={'container d-flex align-items-center'}>
            <div className={'row'}>
            <div className={'col-lg-6 my-auto h-100 text-right ' + styles.imgBg}>  
                <LazyLoadImage
                  alt={''}

                  effect='blur'
                  style={{display:'inline-block', marginTop:'-80px', marginRight:'-40px', width:'100%'}}
          
                  className={'img-fluid'}
                  // className={styles.imgbanner}
                  src={munequito} // use normal <img> attributes as props
                />
            </div>
            <div className={'col-lg-6 pt-5'}>  

                <div className={styles.text_content}>
                  <h1>{t('homeTextLine1')}</h1>
                  <p> {t('homeTextLine3')} </p>
                  <p> {t('homeTextLine4')} </p>
                </div>

            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainSection
