import React , {useState} from 'react'
import * as styles from './Form.module.scss'
import * as Yup from 'yup'
import { Formik, Field, Form as Formi, ErrorMessage } from 'formik'
import { FormGroup, Button, Row, Col } from 'reactstrap'

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import './telinput.scss'
import { useTranslation, withTranslation, Trans } from 'react-i18next';
import WithTranslateFormErrors from './WithTranslateFormErrors';


import { useAlert } from 'react-alert'

const sleep = (ms) => new Promise((r) => setTimeout(r, ms));


function Form ()  {
  const alert = useAlert()

  const { t, i18n } = useTranslation();
  
  const [phone, setPhone] = useState('');
  const [submitting, setSubmitting] = useState(false);


  const formSchema = Yup.object().shape({
    Name: Yup.string()
      .required(t('required'))
      .max(255, t('max255')),
    LastName: Yup.string()
      .min(5, t('max5'))
      .max(25, t('max25'))
      .required(t('required')),
    Email: Yup.string()
      .required(t('required'))
      .email(t('Invalid email'))
      .max(255, t('Max 255 characters')),
    Commerce: Yup.string()
      .min(5, t('min5'))
      .max(25, t('max25'))
      .required(t('required')),
    MobilePhone: Yup.string()
      .required(t('required'))
      .min(8, t('min8')),
    Terms: Yup.boolean().oneOf([true], t('Field must be checked'))
  })

  let input_class = 'form-control '+ styles.input;
  let input_class2 = 'form-control '+ styles.input_2;
  let input_class3 = 'form-control '+ styles.checkbox;
 
  return (
    <div className={styles.form_wrapper}>
{/* 
<button
      onClick={() => {
        alert.success(t("Data successfully sent"), {type: "info"})
      }}
    >
      Show Alert
    </button> */}
      <div className={styles.boxer}>
        <Formik
          initialValues={{
            Name: '',
            LastName: '',
            Commerce: '',
            Email: '',
            MobilePhone: '',
            Terms: false
          }}
          validationSchema={formSchema}
          onSubmit={async (values,{setSubmitting, setErrors, setStatus, resetForm}) => {
            setSubmitting(true)
            // await sleep(500);

          let data = {
            name: values.Name+" "+values.LastName,
            company: values.Commerce,
            email: values.Email,
            phonenumber: values.MobilePhone ,
            assigned: 19,
            status: 6,
            source: 15
          }

          var formBody = [];
          for (var property in data) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(data[property]);
            formBody.push(encodedKey + "=" + encodedValue);
          }

          formBody = formBody.join("&");

           await fetch('https://crm.fideclub.com/api/add_lead', {
          //  await fetch('https://jsonplaceholder.typicode.com/posts', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
              },
              body: formBody
              // body: JSON.stringify(values)
            }).then(function(response) {
              console.log(response)
              resetForm(true)
              setSubmitting(false)
              setPhone("");
              alert.success(t("Data successfully sent"), {type: "info"})
              // alert(JSON.stringify(values, null, 2));
              return response.json();
            });
           
          }}
          onChange={values=>console.log(values)}
          render={({ handleSubmit, handleChange, handleBlur, setFieldTouched, values, errors, touched }) => (
        
          // {({
          //     values,
          //     handleChange,
          //     errors,
          //     touched,
          //     setFieldTouched,
          //     isValid,
          //     handleSubmit,
          //   }) => (
            <WithTranslateFormErrors errors={errors} touched={touched} setFieldTouched={setFieldTouched}>
          <Formi>
            <Row>
              <Col lg={6} md={6} xs={12} className={ styles.first_1}>
                <FormGroup>
                  <Field
                    className={input_class }
                    name='Name'
                    placeholder={t('name')}
                    type='text'
                  />
                  <ErrorMessage
                    name='Name'
                    component='div'
                    className={'field-error '+ styles.text_danger}
                  />
                </FormGroup>
              </Col>
              <Col lg={6} md={6} xs={12} className={ styles.first_2}>
                <FormGroup>
                  <Field
                    className={input_class}
                    name='LastName'
                    placeholder={t('lastName')}
                    type='text'
                  />
                  <ErrorMessage
                    name='LastName'
                    component='div'
                    className={'field-error '+ styles.text_danger}
                  />
                </FormGroup>
              </Col>
            </Row>
           


            <FormGroup>
                  <Field
                    className={input_class}
                    name='Commerce'
                    placeholder={t('commerce')}
                    type='text'
                  />
                  <ErrorMessage
                    name='Commerce'
                    component='div'
                    className={'field-error '+ styles.text_danger}
                  />
                </FormGroup>
            <FormGroup>
              {/* <label htmlFor='Email'>Correo Electronico: </label> */}
              <Field
                className={input_class}
                name='Email'
                placeholder={t('email')}
                type='email'
              />
              <ErrorMessage
                name='Email'
                component='div'
                className={'field-error '+ styles.text_danger}
              />
            </FormGroup>
            
            <FormGroup>
              {/* <Field
                className={input_class}
                name='MobilePhone'
                placeholder=''
                type='number'
              /> */}
              <PhoneInput
                inputClass={input_class2}
                name='MobilePhone'
                country={'gp'}
                value={values.MobilePhone}
                onChange={handleChange('MobilePhone')}
/>
              <ErrorMessage
                name='MobilePhone'
                component='div'
                className={'field-error '+ styles.text_danger}
              />
            </FormGroup>
            <FormGroup>
              <div className={styles.checkbox_w}>
              <Field
                className={input_class3}
                name='Terms'
                placeholder=''
                type='checkbox'
              />
              <span>{t('acceptPrivacy')} </span>
              </div>
              <ErrorMessage
                name='Terms'
                component='div'
                className={'field-error '+ styles.text_danger}
              />
            
            </FormGroup>
            <Row>
              <Col lg={12} md={12}>
                <Button
                disabled={submitting}
                  color='primary'
                  className={'mr-1 mb-1 btn-block '+ styles.buttonenv}
                  type='submit'
                >
                  {t('save')}
                  
                </Button>
              </Col>
            </Row>
          </Formi>
          </WithTranslateFormErrors>
          )}/>
        {/* </Formik> */}
      </div>
    </div>
  )
}

export default Form
